/* EXPLORER ACTION TYPES */
export const SET_SELECTED_EXPLORER = "SET_SELECTED_EXPLORER";
export const SET_ALBUMS = "SET_ALBUMS";
export const SET_TRACKS = "SET_TRACKS";
export const RESET_STATE = "RESET_STATE";
export const SET_EXPLORER_METADATA = "SET_EXPLORER_METADATA";
export const UNSET_FOCUS_EXPLORER = "UNSET_FOCUS_EXPLORER";
export const SET_ARTISTS_FROM_USER = "SET_ARTISTS_FROM_USER";
export const SET_ALBUMS_FROM_LIBRARY = "SET_ALBUMS_FROM_LIBRARY";

export const WIPE_ALBUMS = "WIPE_ALBUMS";
export const WIPE_ARTISTS = "WIPE_ARTISTS";
export const WIPE_PLAYLISTS = "WIPE_PLAYLISTS";
export const WIPE_TRACKS = "WIPE_TRACKS";
export const SET_ITEMS = "SET_ITEMS";
export const GO_PREVIOUS_STATE = "GO_PREVIOUS_STATE";
export const SAVE_PREVIOUS_STATE = "SAVE_PREVIOUS_STATE";
export const LOADING = "LOADING";

/* DESKTOP ACTION TYPES */
export const MOVE_FILE = "MOVE_FILE";
export const CREATE_FILE = "CREATE_FILE";
export const DELETE_FILE = "DELETE_FILE";
